const formDataToObject = (formData: FormData) =>
	Array.from(formData.entries()).reduce((accumulator, [key, value]) => ({ ...accumulator, [key]: value }), {})

const states = {
	success: 'success',
	error: 'error',
	default: 'default'
}

export default {
	name: 'ajaxForm',
	component() {
		// @ts-expect-error setup Alpine types correctly?
		const form: HTMLFormElement = this.$el
		const endpoint = form.getAttribute('action')!
		const method = form.getAttribute('method') || 'GET'

		return {
			loading: false,
			statusText: '',
			status: states.default,

			handleSubmit(e) {
				if (this.loading) return

				this.loading = true

				const formData = new FormData(form)
				const body = JSON.stringify(formDataToObject(formData))

				const res = fetch(endpoint, {
					method,
					body
				})
					.then(res => res.json())
					.then(data => {
						this.statusText = 'Thank you and welcome to the journey with Stho!'
						this.status = states.success
					})
					.catch(() => {
						this.statusText = 'Something went wrong, please try later'
						this.status = states.error
					})
					.finally(() => {
						this.loading = false
					})
			}
		}
	}
}
