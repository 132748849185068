import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
    name: 'hero',
    component() {
        return {
            isMobile: window.innerWidth < 1024,
            isDesktop: window.innerWidth >= 1024,
            flipCtx: null,

            init() {
				this.reveal()
                this.createTimeline()
                window.addEventListener("resize", this.handleResize.bind(this))
            },

            handleResize() {
                this.isMobile = (window.innerWidth < 1024)
                this.isDesktop = (window.innerWidth >= 1024)
                this.createTimeline()
				this.removeOpacity()
            },

			reveal() {
				const logo = document.querySelector('.js-logo-content')
				const title = document.querySelectorAll(".js-hero-title") 
				const text = document.querySelectorAll(".js-hero-text") 
				const btn = document.querySelectorAll(".js-hero-btn")
				const content = document.querySelector('.js-largeCoverInner')

				const $stg = [logo,title,text,btn, content]

				if (this.isDesktop) {
					gsap.to($stg, {
						duration: .4,
						delay: .1,
						alpha: 1,
						ease: 'power3.inOut',
						stagger: { amount: 0.2}
					})
				}
			},

			removeOpacity() {
				const logo = document.querySelector('.js-logo-content')
				const title = document.querySelectorAll(".js-hero-title") 
				const text = document.querySelectorAll(".js-hero-text") 
				const btn = document.querySelectorAll(".js-hero-btn")
				const content = document.querySelector('.js-largeCoverInner')

				const $stg = [logo,title,text,btn, content]

				gsap.to($stg, {
					alpha: 1,
				})
			},

            createTimeline() {
				const logo = document.querySelector('.js-logo-header')
				const videoHolder = document.querySelector('.js-smallCoverHolder')
				const largeCover = document.querySelector(".js-largeCover")
				const wrapperTitle = document.querySelectorAll(".js-hero-titlewrapper") 
				const word = document.querySelectorAll('.word')
				const content = document.querySelector('.js-contentMask')
				const contentMaskWrapper = document.querySelector('.js-contentMaskwrapper')
				const wrapper = document.querySelector('.js-hero-wrapper')

                if (this.isDesktop) {
                    if (this.flipCtx) {
                        this.flipCtx.revert()
                    }

                    this.flipCtx = gsap.context(() => {
						const tl = gsap.timeline({
							defaults:{ease:'linear', force3D: true},
                            scrollTrigger: {
                                trigger: ".js-hero",
                                start: "top top",
                                end: "bottom bottom",
                                scrub: true,
                                markers: false,
                            }
                        })
                        
                        gsap.set([largeCover], { autoAlpha: 1 })
                        gsap.set(content, {autoAlpha: 1})

                        const calculateTransform = () => {
                            if (videoHolder && largeCover) {
                                const videoHolderRect = videoHolder.getBoundingClientRect()
                                const largeCoverRect = largeCover.getBoundingClientRect()
                                const scaleX = videoHolderRect.width / largeCoverRect.width
                                const scaleY = videoHolderRect.height / largeCoverRect.height
                                const scale = Math.min(scaleX, scaleY)
                                
                                const translateX = (videoHolderRect.left + videoHolderRect.width / 2) - (largeCoverRect.left + largeCoverRect.width / 2)
                                const translateY = (videoHolderRect.top + videoHolderRect.height / 2) - (largeCoverRect.top + largeCoverRect.height / 2)
                                
                                return { scale, translateX, translateY }
                            }
                            return { scale: 1, translateX: 0, translateY: 0 }
                        }

                        const { scale, translateX, translateY } = calculateTransform()

                        gsap.set(largeCover, {
                            x: translateX,
                            y: translateY,
                            scale,
                        })

						gsap.set(contentMaskWrapper, {
                            scale,
                        })

						tl.to(largeCover, {
							scale: 1,
							x: 0,
							y: 0,
							// borderRadius: '0px',
							ease: 'linear',
							duration: 1,
							onUpdate: () => {
								if (largeCover && largeCover._gsap) {
									const scaleX = 1 / (largeCover._gsap.scaleX || 1);
									const scaleY = 1 / (largeCover._gsap.scaleY || 1);
									
									const x = -parseFloat(largeCover._gsap.x) || 0;
									const y = -parseFloat(largeCover._gsap.y) || 0;
									
									contentMaskWrapper.style.transform = `scale(${scaleX}, ${scaleY}) translate(${x}px, ${y}px) translateZ(0)`;
								}
							}
						}, 0)

						tl.to([wrapperTitle], {
							xPercent: -32,
							duration: 1,
							ease:'linear',
						}, 0)

						tl.to(word, {
							x: '-9.5rem',
							duration: 1,
							ease:'linear',
						}, 0)

						gsap.set(logo, {
							y: () => {
								const titleElement = wrapperTitle[0];
								const titleRect = titleElement ? {
									top: titleElement.offsetTop,
									left: titleElement.offsetLeft,
									width: titleElement.offsetWidth,
									height: titleElement.offsetHeight
								} : null;
								return titleRect ? `${titleRect.top - 20}px` : '0px';
							},
							ease: 'linear',
						});
						tl.to(logo, {
							y: '0',
							duration: 1,
							ease:'linear',
						}, 0)
                    })
                } else {
                    if (this.flipCtx) {
                        this.flipCtx.revert()
                        this.flipCtx = null
                    }

    
                    gsap.set([largeCover, content, contentMaskWrapper, wrapperTitle, word, logo], { clearProps: "all" })
                }
            }, 
        }
    }
}
