export default {
	name: 'anchorNav',
	component() {
		const titles = Array.from(document.querySelectorAll('[data-title]')).map(title => {
			const textContent = title.textContent
			// Slugify the title
			const slug = textContent?.toLowerCase().replace(/\s+/g, '-')
			return { title: textContent, slug }
		})

		return {
			titles
		}
	}
}
