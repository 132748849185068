export type MediaVideoSrcs = Record<string, string>

export interface VideoLoopProps extends HTMLVideoElement {
	srcs: string[]
	widths: string[]
	desktopOnly: boolean
}

export default {
	name: 'videoLoop',
	component(props: VideoLoopProps) {
		const { srcs, widths, desktopOnly } = props
		const entries = srcs.map((src, i) => ({ src, width: widths[i] })).sort((a, b) => a.width - b.width)
		const video = this.$refs.video

		if (!srcs?.length) {
			console.warn('Video srcs are required')
			return null
		}

		if (srcs.length !== widths.length) {
			console.warn('Video loop srcs & widths length are not equal')
		}

		return {
			index: 0,
			src: entries[0]?.src,

			play: () => {
				const p = video.play()
				if (p !== undefined) {
					p.catch(error => {
						console.warn('video', error)
					})
				}
			},

			pause: () => {
				const p = video.pause()
				if (p !== undefined) {
					p.catch(error => {
						console.warn('video', error)
					})
				}
			},

			handleLoadedMetadata() {
				video.classList.remove('opacity-0')
			},

			init() {
				this.$useResize(video, e => {
					const bounds = e.boundingClientRect || e.target.getBoundingClientRect()

					const entry = entries.filter(({ width }) => {
						return bounds.width < width
					})
					const src = entry[0]?.src

					if (src !== this.src) {
						video.setAttribute('src', src)
						this.src = src
					}
				})

				const handleChildEnter = (elements: IntersectionObserverEntry[]) => {
					const element = elements[0]
					if (element.isIntersecting) {
						this.play()
					} else {
						this.pause()
					}
				}

				this.intersectionObserver = new IntersectionObserver(handleChildEnter)
				this.intersectionObserver.observe(this.$el)
			},

			destroy() {
				if (this.intersectionObserver) this.intersectionObserver.disconnect()
			}
		}
	}
}
