import Lenis from 'lenis'

window._getLenis = () => {
	window._lenis = new Lenis()
	return window._lenis
}

window._getLenis()

const raf = time => {
	window._lenis.raf(time)
	requestAnimationFrame(raf)
}
requestAnimationFrame(raf)
