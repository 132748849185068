export default {
	register: Alpine => {
		// Alpine stores
		const alpineStores = import.meta.glob('./stores/*', { eager: true, import: 'default' })

		for (const path in alpineStores) {
			if (path) {
				const store = alpineStores[path]
				Alpine.store(store.name, store.store())
			}
		}

		// Alpine components
		const alpineComponents = import.meta.glob('./components/**/*.ts', { eager: true, import: 'default' })
		for (const path in alpineComponents) {
			if (path) {
				const component = alpineComponents[path]
				Alpine.data(component.name, component.component)
			}
		}

		// Alpine directives
		// const alpineDirectives = import.meta.glob('./directives/*.js', { eager: true, import: 'default' })

		// for (const path in alpineDirectives) {
		// 	if (path) {
		// 		const directive = alpineDirectives[path]
		// 		Alpine.directive(directive.name, directive.callback)
		// 	}
		// }

		// Alpine magic
		const alpineMagic = import.meta.glob('./magic/**/*', { eager: true, import: 'default' })

		for (const path in alpineMagic) {
			if (path) {
				const magic = alpineMagic[path]
				Alpine.magic(magic.name, magic.callback)
			}
		}
	}
}
