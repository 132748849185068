import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'

gsap.registerPlugin(ScrollToPlugin)


export const isDescendant = (child, parent) => {
	let node = child.parentNode
	while (node != null) {
		if (node === parent) {
			return true
		}
		node = node.parentNode
	}
	return false
}

export const autoScrollTo = (yVal, dur = 1.2, cb) => {
    gsap.to(window, dur, {
        scrollTo: { y: yVal },
		ease: 'power3.inOut',
        onComplete : () => { if (cb) cb.call() },
    })
}

export const DOMPosition = (obj) => {
    let offX = obj.offsetLeft
    let offY = obj.offsetTop
    let parent = obj.offsetParent

    while (parent !== null) {
        offX += parent.offsetLeft
        offY += parent.offsetTop

        parent = parent.offsetParent
    }

    return {
        x: offX,
        y: offY,
    }
}

export const pageOffset = (obj) => {
    let offX = obj.offsetLeft
    let offY = obj.offsetTop
    let parent = obj.offsetParent

    while (parent !== null) {
        offX += parent.offsetLeft
        offY += parent.offsetTop

        parent = parent.offsetParent
    }

    return {
        x: offX,
        y: offY,
    }
}

