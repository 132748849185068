export default {
	name: 'modal',
	component() {
		return {
			init() {
				this.$watch('$store.global.showModal', shouldLock => {
					// this.$store.global.bodyScrollLock(shouldLock)
				})

				this.$nextTick(() => {
					window?._app?.taxi?.on('NAVIGATE_OUT', () => {
						this.$store.global.toggleModal(false)
					})
				})
			},

			close() {
				this.$store.global.toggleModal(false)
			}
		}
	}
}
