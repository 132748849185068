export default {
	name: 'button',
	component() {
		const anchorOffset = 150

		const onClick = (e: Event) => {
			console.log('anchre')
			const target = e.target as HTMLAnchorElement
			const href = target.getAttribute('href')
			if (!href) return

			const id = href.split('#')[1]
			const anchorEl = document.getElementById(id)
			if (!anchorEl) {
				console.warn(`Lien avec ancre -> document.getElementById(${id}) est indéfini`)
				return
			}

			e.preventDefault()
			const offsetTop = anchorEl.getBoundingClientRect().top + window.scrollY
			window.scrollTo({
				top: offsetTop - (anchorOffset || 0),
				behavior: 'smooth'
			})
		}

		return {
			onClick
		}
	}
}
