export default {
	name: 'accordion',
	component(props = { defaultOpen: null }) {
		return {
			selected: props.defaultOpen,

			handleChange(e) {
				const { id, checked } = e.target
				this.selected = checked ? id : null
			}
		}
	}
}
