export default {
	name: 'variantSelector',
	component() {
		return {
			isOpen: false,

			handleClick(e: Event) {
				e.preventDefault()
				this.isOpen = !this.isOpen
				document.body.classList.toggle('selector-open')
				const button = document.querySelector('.variant-selector-list')
				button?.setAttribute('aria-expanded', this.isOpen.toString())
			}
		}
	}
}
