export default {
	name: 'newsletterModal',
	component() {
		const hideNewsletterModal = JSON.parse(sessionStorage.getItem('hideNewsletterModal') || 'false')
		return {
			modal: null as any,
			hideNewsletterModal,
			success: false,
			error: null as HTMLElement | null,
			subtitle: null as HTMLElement | null,
			form: null as HTMLElement | null,

			init() {
				this.$nextTick(() => {
					const newsletterModalEl = document.getElementById('newsletterModal')
					if (this.modal && this.modal.$refs) {
						this.subtitle = this.modal.$refs.subtitle as HTMLElement || null
						this.error = this.modal.$refs.error as HTMLElement || null
						this.form = this.modal.$refs.form as HTMLElement || null
					}

					const observer = new MutationObserver(mutations => {
						mutations.forEach(mutation => {
							if (mutation.type === 'attributes') {
								const submitSuccess = (mutation.target as HTMLElement).getAttribute('data-success')
								if (submitSuccess) {
									this.handleSuccess()
								}
							}
						})
					})
					if (this.form) {
						observer.observe(this.form, { attributes: true })
					}

					if (!hideNewsletterModal && newsletterModalEl === this.modal?.$el) {
						setTimeout(() => {
							this.$store.global.toggleModal(true)
						}, 15000)
					}

					this.$watch('$store.global.showModal', showModal => {
						if (newsletterModalEl && !showModal) {
							sessionStorage.setItem('hideNewsletterModal', JSON.stringify(true))
						}
					})
				})
			},

			handleSuccess() {
				this.success = false

				this.resetModalText()

				this.success = true
				setTimeout(() => {
					this.$store.global.toggleModal(false)
				}, 6000)
			},

			onError() {
				this.error?.classList.add('block')
				this.error?.classList.remove('hidden')
			},
			resetError() {
				this.error?.classList.remove('block')
				this.error?.classList.add('hidden')
			},

			onModalError() {
				this.onError()
				this.subtitle?.classList.remove('block')
				this.subtitle?.classList.add('hidden')
			},
			resetModalText() {
				this.resetError()
				this.subtitle?.classList.add('block')
				this.subtitle?.classList.remove('hidden')
			}
		}
	}
}
