export default {
	name: 'useResizeObserver',
	callback: (el = document.documentElement, { cleanup }) => {
		return (ref, callback, options = null) => {
			if (!window.ResizeObserver) return

			const resizeObserver = new ResizeObserver(entries => {
				for (const entry of entries) {
					callback(entry.contentRect)
				}
			})

			resizeObserver.observe(el)

			cleanup(() => {
				if (resizeObserver) {
					resizeObserver.disconnect()
				}
			})
		}
	}
}
