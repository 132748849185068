import gsap from 'gsap'

export default {
	name: 'header',
	component() {
		return {
			isMobileNavOpen: false,
			isBannerHidden: false,
			lastScrollPosition: 0,
			isMobile: window.innerWidth < 768,
			hasScrolled: Boolean(window.scrollY >= window.innerHeight),
			currentTimeline: null,
			eventTimer: null,
	
			init() {
				// add on window since _lenis.on will not be persisted after a body scroll lock because we destroy the instance
				window.addEventListener('scroll', this.handleScroll.bind(this), { passive: true })
				window.addEventListener('resize', this.handleResize.bind(this))
				this.eventTimer = setTimeout(() => {
					this.toggleMenu(false)
				}, 3000)
			},
			handleScroll(e) {
				if (!this.$store.global.hasBodyLock) {
					this.hasScrolled = Boolean(window._lenis.animatedScroll >= this.$store.global.viewport.vh)
				}

				const scrollPosition = window.pageYOffset || document.documentElement.scrollTop
				const scrollDelta = scrollPosition - this.lastScrollPosition

				const scrollTolerance = 5

					// eslint-disable-next-line @shopify/binary-assignment-parens
				const isScrollingDown = scrollDelta > scrollTolerance
				// eslint-disable-next-line @shopify/binary-assignment-parens
				const isScrollingUp = scrollDelta < -scrollTolerance

				this.lastScrollPosition = scrollPosition

				if (scrollPosition > 100) {
					document.body.classList.add('has-bg')
				} else {
					document.body.classList.remove('has-bg')
				}
			},
			handleBurgerClick() {
				this.toggleMenu()
			},
			toggleMenu(shouldOpen = !this.isMobileNavOpen) {
				if (shouldOpen === this.isMobileNavOpen) return

				clearTimeout(this.eventTimer)

				this.isMobileNavOpen = shouldOpen

				document.body.classList.toggle('menu-open', this.isMobileNavOpen)

				const $stgElements = document.querySelectorAll('.js-menu-stg')
				
				const tl = gsap.timeline({ defaults: { force3D: true } })

				if (this.currentTimeline) {
					this.currentTimeline.kill()
				}

				tl.to($stgElements, {
					delay: shouldOpen ? 0.2 : 0.4,
					duration: shouldOpen ? 0.5 : 0.2,
					alpha: shouldOpen ? 1 : 0,
					y: shouldOpen ? 0 : 20,
					ease: shouldOpen ? 'power2.out' : 'power2.in',
					stagger: { amount: shouldOpen ? 0.2 : -0.2 }
				})

				this.currentTimeline = tl
			},
			closeMobileNav() {
				this.toggleMenu(false)
			},
			handleResize() {
				const newIsMobile = (window.innerWidth < 768)
				if (newIsMobile !== this.isMobile) {
					this.isMobile = newIsMobile
					this.closeMobileNav()
				}
			},
		}
	}
}
