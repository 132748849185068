export default {
	name: 'useResize',
	callback: (el, { cleanup }) => {
		const component = el._x_dataStack[0]

		return (ref, callback, options = null) => {
			if (!window.IntersectionObserver) return

			const intersectionObserver = new IntersectionObserver(entries => {
				const entry = entries[0]
				if (entry) {
					callback(entry)
					intersectionObserver.unobserve(entry.target)
				}
			}, options || {})

			intersectionObserver.observe(ref)

			component.$useWindowResize(() => {
				if (ref && intersectionObserver) {
					intersectionObserver.observe(ref)
				}
			})

			cleanup(() => {
				if (intersectionObserver) {
					intersectionObserver.disconnect()
				}
			})
		}
	}
}
