export default {
	name: 'prefersReducedMotion',
	store() {
		return {
			prefersReducedMotion: false,
			init() {
				this.initPrefersReducedMotion()
			},
			initPrefersReducedMotion() {
				const mql = window.matchMedia('(prefers-reduced-motion: reduce)')
				mql.addEventListener('change', this.handlePrefersReducedMotionChange.bind(this), { passive: true })
				this.prefersReducedMotion = mql.matches
			},
			handlePrefersReducedMotionChange(event: MediaQueryListEvent) {
				this.prefersReducedMotion = event.matches
			}
		}
	}
}
