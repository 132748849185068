import { getDevice, isTouchDevice } from '@/utils/devices'
import { precision } from '@/utils/maths'

const defaultOptions: ScrollAnimationOptions = {
	useViewportRatio: false,
	scaleToFit: false,
	offset: [
		[0.5, 0.5],
		[1, 0]
	],
	x: 0,
	y: 0,
	scaleInit: 1,
	rotateInit: 0,
	rotate: 0,
	easingFunction: (value: number) => value,
	transform: null,
	mediaQueries: ['desktop', 'tablet', 'mobile'],
	unit: 'px',
	disableTouchDevice: true,
	parallax: false,
	parallaxDistance: 100,
	parallaxClip: true,
	ignoreReducedMotion: false
}

export default {
	name: 'scrollAnimation',
	component(options: ScrollAnimationOptions) {
		return {
			options: {} as ScrollAnimationOptions,
			angle: 0,
			_height: 0,
			_x: 0,
			_y: 0,
			prevX: null as number | null,
			prevY: null as number | null,
			needsClean: false,
			$el: null as HTMLElement | null,
			$refs: { animatedElement: null as HTMLElement | null },

			init() {
				this.options = { ...defaultOptions, ...options }

				this.angle = this.options.rotateInit ?? 0
				this._height = 0
				this._x = 0
				this._y = 0
				this.prevX = null
				this.prevY = null
				this.needsClean = false

				if (this.$el) {
					if (this.options.parallax) {
						this.$el.classList.toggle('overflow-clip', this.options.parallaxClip)
					}

					// @ts-expect-error: $useScrollRatio is added by Alpine.js
					this.$useScrollRatio(this.$el, this.handleScroll.bind(this), {
						offset: this.options.offset,
						easingFunction: this.options.easingFunction,
						mediaQueries: this.options.mediaQueries
					})
				}
			},

			handleScroll(ratio: number, { scrollY, top, height, viewport, originalRatio }: ScrollInfo) {
				// @ts-expect-error: $store types are not available in Alpine components
				if (this.$store.global.prefersReducedMotion && !this.options.ignoreReducedMotion) {
					return
				}

				this._height = height

				const enabled = this.options.mediaQueries?.includes(getDevice(viewport.width)) && !(this.options.disableTouchDevice && isTouchDevice())
				if (!enabled) return

				const dx = this.options.useViewportRatio ? this.options.x! * viewport.width : this.options.x
				const dy = this.options.parallax
					? this.options.parallaxDistance
					: this.options.useViewportRatio
						? this.options.y! * viewport.documentHeight
						: this.options.y
				let scale = this.options.scaleInit ?? 0

				if (this.options.parallax || this.options.scaleToFit) {
					const dTotal = 2 * dy!
					const scrollDistance = (viewport.documentHeight - height) / 2
					const scrollDistanceTotal = viewport.documentHeight + height
					const deltaD = (scrollDistance * dTotal) / scrollDistanceTotal
					scale += Math.max(1, (height + deltaD * 2) / height) - 1
				}

				const initY = top - scrollY
				const vpHeight = viewport.documentHeight

				this._x = ratio * (this.options.x ?? 0) * (this.options.useViewportRatio ? Math.abs(dx!) : 1)
				this._y = ratio * (dy ?? 0) * (this.options.useViewportRatio ? Math.abs(dy!) : 1)

				if (this.options.rotate || this.options.rotateInit) {
					const destRotation = (-(this.options.rotate ?? 0) * initY) / vpHeight + (this.options.rotateInit ?? 0)
					this.angle = destRotation
				}

				const t = {
					x: precision(this._x),
					y: precision(this._y),
					rotate: this.options.rotate
				}

				const newY = initY + this._y

				const inView = Boolean(newY + height >= 0 && newY <= vpHeight) || Boolean(initY + height >= 0 && initY <= vpHeight)

				const props = {
					ratio,
					originalRatio,
					top,
					height,
					viewport,
					inView,
					enabled: false
				}

				const rotateTransformation = this.angle ? ` rotate(${this.angle}deg)` : ''

				if (this.$refs.animatedElement) {
					if (inView && (t.x !== this.prevX || t.y !== this.prevY)) {
						this.$refs.animatedElement.style.transform = this.options.transform
							? this.options.transform(t, { ...props, enabled: true })
							: `translate3d(${t.x}${this.options.unit},${t.y}${this.options.unit},0)${rotateTransformation} scale(${scale})`
						this.$refs.animatedElement.style.willChange = 'transform'
						this.needsClean = true
					} else if (!inView && this.needsClean) {
						this.$refs.animatedElement.style.transform = this.options.transform
							? this.options.transform(t, props)
							: `translate(${t.x}${this.options.unit},${t.y}${this.options.unit})${rotateTransformation} scale(${scale})`
						this.$refs.animatedElement.style.willChange = ''
						this.needsClean = false
					}
				}

				this.prevX = t.x
				this.prevY = t.y
			}
		}
	}
}

interface ScrollAnimationOptions {
	useViewportRatio?: boolean
	scaleToFit?: boolean
	offset?: [number, number][]
	x?: number
	y?: number
	scaleInit?: number
	rotateInit?: number
	rotate?: number
	easingFunction?: (value: number) => number
	transform?: ((t: any, props: any) => string) | null
	mediaQueries?: string[]
	unit?: string
	disableTouchDevice?: boolean
	parallax?: boolean
	parallaxDistance?: number
	parallaxClip?: boolean
	ignoreReducedMotion?: boolean
}

interface ScrollInfo {
	scrollY: number
	top: number
	height: number
	viewport: {
		width: number
		documentHeight: number
	}
	originalRatio: number
}

// Example usage:

// 1. Basic scroll animation
// <div x-data="scrollAnimation({ x: 100, y: 200 })">
//     <div x-ref="animatedElement">This element will move 100px horizontally and 200px vertically during scroll</div>
// </div>

// 2. Parallax effect
// <div x-data="scrollAnimation({ parallax: true, parallaxDistance: 150 })" class='h-screenMin'>
//     <img x-ref="animatedElement" src="image.jpg" alt="Parallax Image" class="w-full h-full object-cover">
// </div>

// 3. Rotation during scroll
// <div x-data="scrollAnimation({ rotate: 360, useViewportRatio: true })">
//     <div x-ref="animatedElement">This element will rotate 360 degrees during scroll</div>
// </div>

// 4. Scale effect
// <div x-data="scrollAnimation({ scaleInit: 0.5, scaleToFit: true })">
//     <div x-ref="animatedElement">This element will scale from 0.5 to fit during scroll</div>
// </div>

// 5. Custom transform function
// <div x-data="scrollAnimation({
//     transform: (t, props) => `translate3d(${t.x}px, ${t.y}px, 0) scale(${1 + props.ratio})`
// })">
//     <div x-ref="animatedElement">This element will have a custom transform applied</div>
// </div>

// 6. Ignore reduced motion preference
// <div x-data="scrollAnimation({ x: 100, y: 200, ignoreReducedMotion: true })">
//     <div x-ref="animatedElement">This element will animate even if the user prefers reduced motion</div>
// </div>
