import { devices } from '@/utils/devices'
import { autoScrollTo, DOMPosition } from '@/utils/dom'

export default {
	name: 'global',
	store() {
		return {
			isMinicartVisible: false,
			showModal: false,
			isSearchVisible: false,
			minicartLessPaper: false,
			device: devices[0].name as 'mobile' | 'tablet' | 'desktop',
			viewport: { vw: window.innerWidth, vh: window.innerHeight },
			// prefersReducedMotion: false,
			hasBodyLock: false,
			bodyLocksCount: 0,
			savedScrollY: window.scrollY,

			init() {
				this.initLiquidAJaxCart()

				this.setDevice()
				window.addEventListener('resize', this.setDevice.bind(this), { passive: true })

				// this.initPrefersReducedMotion()
				this.initAnchors()
			},

			initLiquidAJaxCart() {
				document.addEventListener('liquid-ajax-cart:request-end', event => {
					const { requestState, cart } = event.detail

					if (requestState.requestType === 'add') {
						if (requestState.responseData?.ok) {
							this.isMinicartVisible = true
						}
					}

					this.cart = cart
				})
			},
			setDevice() {
				this.viewport.vw = window.innerWidth
				this.viewport.vh = window.innerHeight

				const currentDevice = devices.find(d => d.breakpoint < this.viewport.vw)?.name || devices[0].name
				this.device = currentDevice
			},
			bodyScrollLock(shouldLock) {
				if (shouldLock) {
					if (!this.hasBodyLock) {
						this.savedScrollY = window._lenis.scroll
						window._lenis.destroy();

						document.body.style.position = "fixed"
						document.body.style.top = `-${this.savedScrollY}px`;
					}
					this.bodyLocksCount += 1
					this.hasBodyLock = shouldLock
				} else {
					this.bodyLocksCount -= 1

					if (this.hasBodyLock && this.bodyLocksCount === 0) {
						document.body.style.removeProperty('position')
						document.body.style.removeProperty('top')

						window._getLenis()
						window._lenis.scrollTo(this.savedScrollY, { immediate: true, force: true })

						this.hasBodyLock = shouldLock
					}
				}
			},
			// initPrefersReducedMotion() {
			// 	const mql = window.matchMedia("(prefers-reduced-motion: reduce)");
			// 	mql.addEventListener("change", this.handlePrefersReducedMotionChange.bind(this), { passive: true });
			// 	// this.prefersReducedMotion = mql.matches;
			// },
			// handlePrefersReducedMotionChange(event: MediaQueryListEvent) {
			// 	// this.prefersReducedMotion = event.matches
			// },
			toggleModal(value: boolean) {
				this.showModal = value;
				// this.bodyScrollLock(value);
			},
			initAnchors() {
				document.querySelectorAll('.js-anchorLink').forEach(anchor => {
					anchor.addEventListener('click', this.onAnchorClick.bind(this))
				})
			},
			onAnchorClick(e) {
				const target = e.target.closest('.js-anchorLink')
				if (!target) return
				e.preventDefault()
				
				const anchor = target.getAttribute('data-anchor')
				const currentPath = window.location.pathname
				
				if (anchor) {
					if (currentPath === '/') {
						this.scrollToID(`#${anchor}`, 100)
					} else {
						const $target = document.querySelector(`#${anchor}`)
						if ($target) {
							this.scrollToID(`#${anchor}`, 100)
						} else {
							window.location.href = `/#${anchor}`
						}
					}
				}
			},
			scrollToID(id, offset) {
				const $target = document.querySelector(id)
			
				if ($target) {
					const { y } = DOMPosition($target)
					autoScrollTo(y - offset)
				}
			},
		}
	}
}
