import gsap from 'gsap'
import { Flip } from 'gsap/dist/Flip'
import canAutoPlay from 'can-autoplay'

gsap.registerPlugin(Flip)

export default {
    name: 'splashIntro',
    component() {
        return {
            isLoaded: false,
            hasAnimated: false, 

			isMobile: window.innerWidth < 1024,
            isDesktop: window.innerWidth >= 1024,

            async init() {
				const desktopVideo = document.querySelector('.splash-intro__video')
				const mobileVideo = document.querySelector('.splash-intro__video-mobile')
				const poster = document.querySelector('.poster')
				const posterMobile = document.querySelector('.poster-mobile')

				const {result, error} = await canAutoPlay.video({
					inline: true,
					muted: true,
				})

				if(result) {
					poster.style.opacity = 0
					posterMobile.style.opacity = 0
				} else {
					desktopVideo.style.opacity = 0
					mobileVideo.style.opacity = 0
				}

				if (this.isMobile) {
					// mm.add("(max-width: 1023px)", () => {
						if (this.hasAnimated) {
							const logoMobile = document.querySelector('.splash-intro__logo-mobile')

							gsap.set(logoMobile, {
								opacity: 0,
							})

							return
						} 

						if (this.isLoaded) return

						this.hasAnimated = true
						this.isLoaded = true

						const logoDesktop = document.querySelector('.splash-intro__logo')
						const logo = document.querySelector('.logo_mobile')
						const letters = document.querySelectorAll('.logo-letter')
						const bg = document.querySelector('.splash-intro__bg')
						const video = document.querySelector('.splash-intro__video')
						const navlinks = document.querySelectorAll('.footer_link')
						const stg = document.querySelectorAll('.js-stg')
						const logoMobile = document.querySelector('.splash-intro__logo-mobile')

						const tl = gsap.timeline({ defaults: { force3D: true, delay: 1 }, onComplete: () => {
							gsap.set(letters, {
								fillOpacity: 1,
							})
						}})

						gsap.set(logoDesktop, {
							opacity: 1,
						})

						gsap.set(navlinks, {
							y: 40,
							// opacity: 0
						})

						gsap.set(logoMobile, {
							opacity: 1,
						})

						gsap.set(letters, {
							fillOpacity: 0.2,
						})

						gsap.set(video, {
							y: '100%'
						})

						gsap.set([logo, stg], {
							opacity: 0,
							y: 40,
						})

						gsap.set(bg, {
							y: '0%',
						})

						tl.to(
							bg,
							{
								duration: 1.5,
								y: '-100%',
								ease: 'expo.inOut'
							},
							0
						)

						tl.to(
							video,
							{
								duration: 1.5,
								y: '0%',
								ease: 'expo.inOut'
							},
							0
						)

						tl.to(
							logoMobile,
							{
								duration: 1.4,
								y: -200,
								opacity: 0,
								rotate: 0,
								ease: 'expo.inOut',
								onComplete: () => {
									gsap.set(logo, {
										display: 'hidden',
									})
								}
							},
							0
						)

						tl.to(
							[logo, stg],
							{
								duration: 0.6,
								delay: 1,
								opacity: 1,
								y: 0,
								ease: 'power2.out',
								stagger: -0.1
							},
							0.5
						)

						tl.to(
							navlinks,
							{
								duration: 0.8,
								y: 0,
								opacity: 1,
								ease: 'power2.out',
								stagger: 0.1,
								overwrite: false,
							},
							0.5
						)
					// })
				} else {
					// mm.add("(min-width: 1024px)", () => {
						if (this.hasAnimated) return; 
						if (this.isLoaded) return

						const cleanElement = () => {
							gsap.set(logo, {
								clearProps: 'all',
							})
						}

						const tl = gsap.timeline({ defaults: { force3D: true, delay: 1 } })
						const logo = document.querySelector('.splash-intro__logo')
						const letters = document.querySelectorAll('.logo-letter')
						const bg = document.querySelector('.splash-intro__bg')
						const video = document.querySelector('.splash-intro__video')
						const navlinks = document.querySelectorAll('.footer_link')
						const stg = document.querySelectorAll('.js-stg')
						const logoMobile = document.querySelector('.splash-intro__logo-mobile')

						const initialState = Flip.getState(logo)

						gsap.set(logoMobile, {
							opacity: 0,
						})

						gsap.set(logo, {
							opacity: 1,
						})

						gsap.set(logo, {
							top: '50%',
							left: '50%',
							y: '-50%',
							x: '-100%',
							scale: 2,
							rotate: -90
						})

						Flip.to(initialState, {
							delay: 1,
							duration: 1.5,
							ease: 'expo.inOut',
							scale: true,
							absolute: true,
							onComplete: () => {
								this.isLoaded = true
								this.hasAnimated = true
								cleanElement()
								gsap.set(logo, {
									opacity: 1,
								})
							}
						})

						gsap.set(navlinks, {
							y: 40,
							// opacity: 0
						})

						gsap.set(video, {
							y: '100%'
						})

						gsap.set(letters, {
							fillOpacity: 0.2,
						})

						gsap.set(stg, {
							opacity: 0,
							y: 40,
						})

						gsap.set(bg, {
							y: '0%',
						})

						tl.to(
							letters,
							{
								duration: 1.5,
								fillOpacity: 1,
								ease: 'expo.inOut'
							},
							0
						)

						tl.to(
							bg,
							{
								duration: 1.5,
								y: '-100%',
								ease: 'expo.inOut'
							},
							0
						)

						tl.to(
							video,
							{
								duration: 1.5,
								y: '0%',
								ease: 'expo.inOut'
							},
							0
						)

						tl.to(
							stg,
							{
								duration: 0.6,
								delay: 1,
								opacity: 1,
								y: 0,
								ease: 'power2.out',
								stagger: -0.1
							},
							0.5
						)

						tl.to(
							navlinks,
							{
								duration: 0.8,
								y: 0,
								opacity: 1,
								ease: 'power2.out',
								stagger: 0.1,
								overwrite: true,
							},
							0.5
						)
					// })
				}
            }
        }
    }
}