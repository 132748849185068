export default {
	name: 'minicart',
	component(props) {
		const { giftBox, isGift, lessPaper } = props

		return {
			isGift,
			lessPaper,
			handleChangeGiftBox(e: MouseEvent) {
				this.isGift = e.currentTarget.checked
				if (e.currentTarget.checked) {
					liquidAjaxCart.add({ items: [{ id: giftBox }] })
					this.$store.global.minicartLessPaper = false
					this.$refs.lessPaper.disabled = true
				} else {
					liquidAjaxCart.cart.items.forEach(item => {
						if (giftBox === item.variant_id) {
							liquidAjaxCart.change({ id: item.key, quantity: 0 })
						}
					})
				}
			},
			handleChangeLessPaper(e: MouseEvent) {
				this.$store.global.minicartLessPaper = e.currentTarget.checked
			}
		}
	}
}
