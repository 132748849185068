import '@/lenis'
import 'liquid-ajax-cart'

import Alpine from 'alpinejs'
import AlpineCollapse from '@alpinejs/collapse'
import AlpineFocus from '@alpinejs/focus'
import AlpineMorph from '@alpinejs/morph'
import { Core } from '@unseenco/taxi'

// import { BaseRenderer } from '@/renderers/default'
import AlpineGlobals from '@/alpine/index'
// import FadeTransition from '@/transitions/fade'

class App {
	taxi!: Core
	modules: any

	constructor() {
		setTimeout(this.init, 0)
	}

	init = () => {
		// Register and initialize AlpineJS
		window.Alpine = Alpine
		Alpine.plugin([AlpineCollapse, AlpineFocus, AlpineMorph])
		AlpineGlobals.register(Alpine)
		Alpine.start()

		// liquidAjaxCart
		liquidAjaxCart.conf('quantityTagDebounce', 200)
		liquidAjaxCart.conf('updateOnWindowFocus', false)

		// taxi
		// this.taxi = new Core({
		// 	links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.jdgm-paginate__page):not(.jdgm-star)',
		// 	renderers: {
		// 		default: BaseRenderer,
		// 	},
		// 	transitions: {
		// 		default: FadeTransition
		// 	},
		// 	reloadJsFilter: element => element.dataset.taxiReload !== undefined || element.dataset.locksmith !== undefined
		// })
	}
}

window._app = new App()
