import { WindowEvent } from '@/alpine/magic/hooks/useWindowEvent'

export default {
	name: 'useWindowResize',
	callback: el => {
		const component = el._x_dataStack[0]

		return (cb: (e: WindowEvent) => void, triggerInit = false, useEffectProps = [], debounceDelay = 40) => {
			component.$useWindowEvent('resize', cb, triggerInit, useEffectProps, debounceDelay)
		}
	}
}
