import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
    name: 'cardsPush',
    component() {
        return {
            isDesktop: window.innerWidth >= 1024,
            gsapContext: null,

            init() {
                window.addEventListener('resize', () => this.handleResize())
                this.createTimeline()
            },

            handleResize() {
                this.isDesktop = (window.innerWidth >= 1024)
                if (this.gsapContext) {
                    this.gsapContext.revert()
                }
                this.createTimeline()
            },

            createTimeline() {
                if (this.isDesktop) {
                    const section = document.querySelector('.js-cardsPush')
                    
                    this.gsapContext = gsap.context(() => {
                        const slider = document.querySelector('.slideshow-list')
                        const title = document.querySelector('.js-cardsPush-title')

                        const tl = gsap.timeline({
                            defaults: {force3D: true, ease: 'none'},
                            scrollTrigger: {
                                trigger: section,
                                start: 'top top',
                                end: 'bottom bottom',
                                scrub: 1,
                            }
                        })

                        tl.to(slider, {
                            x: () => -(slider.scrollWidth - window.innerWidth + window.innerWidth) / 2,
                        }, 0)

                        tl.to(title, {
                            scale: .7,
                            alpha: .5,
                            transformOrigin: 'center',
                        }, 0)
                    }, section)
                }
            },
        }
    }
}
